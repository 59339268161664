import React, { useRef, useState } from 'react';

import { BlogCommentType } from '../../../../../../../api/blogServerResponse';
import { getTimeDateForBlogEvent } from '../../../../../../../utils/blogUtils/getTimeDateForBlogEvent';
import classes from './CommentItem.module.css';
import { Button } from '@consta/uikit/Button';
import { IconMeatball } from '@consta/uikit/IconMeatball';
import { Text } from '@consta/uikit/Text';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { IconComponent } from '@consta/uikit/Icon';
import ReportsModalDelete from '../../../../../../../Components/Reports/ReportsModalDelete/ReportsModalDelete';
import { reportsApi } from '../../../../../../../api/reportsApi';
import { pushSnackBarItemReports, selectorIsAdminMetric } from '../../../../../../../store/appSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/hooks';
import { blogApi } from '../../../../../../../api/blogApi';

interface ICommentItemProps {
  comment: BlogCommentType;
  newsId?: string;
  updateNews: () => void;
}
type ContextMenuItem = {
  label: string;
  icon: IconComponent;
  status?: string;
  value: string;
};
const CommentItem: React.FC<ICommentItemProps> = ({ comment, newsId, updateNews }) => {
  const dispatch = useAppDispatch();
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const isPortalAdmin = useAppSelector(selectorIsAdminMetric);

  const contextMenuItems: ContextMenuItem[] = [
    {
      label: 'Удалить',
      value: 'DELETE',
      icon: IconTrash,
      status: 'alert',
    },
  ];

  const onItemClick = (item: ContextMenuItem) => {
    setIsOpenDeleteModal(true);
    return () => setIsOpenContextMenu(false);
  };
  const renderLeftSideItem = (item: any) => {
    const Icon = item.icon;
    return <Icon size="s" view={item.status} />;
  };

  const deleteComment = async () => {
    await blogApi.deleteBlogComment(newsId, comment._uuid);
    setIsOpenDeleteModal(false);
    dispatch(
      pushSnackBarItemReports({
        key: Math.random(),
        message: `Комментарий удален`,
        status: 'success',
        autoClose: 5,
      })
    );
    updateNews();
  };

  return (
    <div className={classes.container}>
      <div className={classes.userNameContainer}>
        <Text className={classes.userName}>{comment.person.last_name}</Text>
        {comment.person.first_name && (
          <Text className={classes.userName}>&nbsp;{`${comment.person.first_name.slice(0, 1)}.`}</Text>
        )}
        {comment.person.middle_name && (
          <Text className={classes.userName}>&nbsp;{`${comment.person.middle_name.slice(0, 1)}.`}</Text>
        )}
      </div>
      <Text className={classes.userPosition}>{comment.person.position}</Text>
      <Text className={classes.dateTime}>{getTimeDateForBlogEvent(comment._create_ts)}</Text>
      <Text className={classes.text}>{comment.value}</Text>
      <div className={classes.delimiter} />
      <div className={classes.btnContainer}>
        {isPortalAdmin && (
          <Button
            label="more"
            view="clear"
            iconLeft={IconMeatball}
            onlyIcon
            size={'s'}
            ref={ref}
            onClick={() => setIsOpenContextMenu((prev) => !prev)}
          />
        )}
        <ContextMenu
          items={contextMenuItems}
          isOpen={isOpenContextMenu}
          getItemLeftSide={renderLeftSideItem}
          anchorRef={ref}
          getItemOnClick={(item) => onItemClick(item)}
          direction="downStartRight"
          onClickOutside={() => setIsOpenContextMenu(false)}
        />
      </div>
      <ReportsModalDelete
        isModalOpen={isOpenDeleteModal}
        setIsModalOpen={setIsOpenDeleteModal}
        modalDeleteTitle={'Вы действительно хотите удалить комментарий?'}
        onConfirmDelete={() => deleteComment()}
      />
    </div>
  );
};

export default CommentItem;
