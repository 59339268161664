import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CustomEditor } from '../../../Components/CustomEditor/CustomEditor';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  getAdminBlogRolesThunk,
  getBlogNewsTagsThunk,
  getBlogNewsTypesThunk,
  postBlogNewsThunk,
  publishBlogNewsThunk,
  selectorBlogRoles,
  selectorViewSelectorNewsTags,
  selectorViewSelectorNewsTypes,
  updateBlogNewsThunk,
} from '../../../store/adminBlogSlice';
import { BlogItemTagType, ViewSelectorItemType } from '../../../types/types';
import classes from './AdminBlogNewsForm.module.css';
import { Attachment } from '@consta/uikit/Attach';
import { Button } from '@consta/uikit/Button';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
// import { Combobox } from '@consta/uikit/Combobox';
import { Combobox } from '@consta/uikit/ComboboxCanary';

import { DragNDropField } from '@consta/uikit/DragNDropField';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { IconPhoto } from '@consta/uikit/IconPhoto';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import classNames from 'classnames';
import { useAdminBlogNews } from '../../../hooks/useAdminBlogNews';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Switch } from '@consta/uikit/Switch';
import { DatePicker } from '@consta/uikit/DatePicker';
import { pushSnackBarItemReports } from '../../../store/appSlice';
import { getCommandUsersThunk, selectorCommandUsers } from '../../../store/commandSlice';
import { IconAdd } from '@consta/uikit/IconAdd';
import UserSelect from '../../../Components/ContactsForm/UserSelect/UserSelect';

interface IAdminBlogPageProps {}
interface UsersType {
  id: string;
  label: string;
}

const AdminBlogNewsForm: React.FC<IAdminBlogPageProps> = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  console.log(searchParams);
  const suggestionType = searchParams.get('type');
  const suggestionValue = searchParams.get('value');
  console.log(suggestionValue);

  const navigate = useNavigate();

  let { id } = useParams();
  const [blogItem, isLoading] = useAdminBlogNews(id);

  const types: ViewSelectorItemType[] = useAppSelector(selectorViewSelectorNewsTypes).filter(
    (el) => el.slug !== 'best_practice'
  );
  const tags: BlogItemTagType[] = useAppSelector(selectorViewSelectorNewsTags);
  const [tagList, setTagList] = useState(tags);
  const [typeValue, setTypeValue] = useState<ViewSelectorItemType>({
    name: 'Новость',
    slug: 'news',
  });
  const roles = useAppSelector(selectorBlogRoles);

  const [tagsValue, setTagsValue] = useState<BlogItemTagType[] | null>();
  const [cover, setCoverValue] = useState<File | null>();
  const [name, setName] = useState<string | null>('');
  const [textValue, setTextValue] = useState<string | null>(suggestionValue);
  const [uuid, setUuid] = useState<string | null>(null);
  const [checked, setIsChecked] = useState<boolean>(false);
  const [publishDateValue, setPublishDaeValue] = useState<Date | null>(null);
  const [eventDateFrom, setEventDateFrom] = useState<Date | null>(null);
  const [eventDateTo, setEventDateTo] = useState<Date | null>(null);
  const [place, setPlace] = useState<string | null>('');
  const [link, setLink] = useState<string | null>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const [speakers, setSpeakers] = useState<UsersType[] | null>([
    {
      id: '',
      label: '',
    },
  ]);
  const [speakersRoles, setSpeakersRoles] = useState<UsersType[] | null>([
    {
      id: '',
      label: '',
    },
  ]);

  useEffect(() => {
    dispatch(getCommandUsersThunk(''));
    dispatch(getBlogNewsTypesThunk());
    dispatch(getBlogNewsTagsThunk());
    dispatch(getAdminBlogRolesThunk());
  }, []);

  useEffect(() => {
    setTagList(tags);
  }, [tags]);
  const setSearch = useCallback((searchString: string) => {
    setSearchValue(searchString);
    console.log(searchString);
    dispatch(getCommandUsersThunk(searchString)).then(() => {});
  }, []);

  useEffect(() => {
    setName(blogItem?.name);
    setTagsValue(blogItem?.tags);
    setUuid(blogItem?._uuid);
    if (!blogItem) {
      setTextValue(suggestionValue);
    } else {
      setTextValue(blogItem?.description);
    }
    if (blogItem?.publication_date) {
      setPublishDaeValue(new Date(blogItem?.publication_date));
      setIsChecked(true);
    }

    const type = types.find((el) => el.slug === blogItem?.type);
    if (type) {
      setTypeValue({ name: type.name, slug: type.slug });
    }

    if (blogItem) {
      setEventDateFrom(new Date(blogItem?.ts_from));
      setEventDateTo(new Date(blogItem?.ts_to));
      setLink(blogItem?.link);
      setPlace(blogItem?.place_name);
      if (blogItem?.persons?.length) {
        const tmpSpeakers: UsersType[] = [];
        const tmpRoles: UsersType[] = [];
        blogItem?.persons?.map((el: any) => {
          tmpSpeakers.push({
            id: el._uuid,
            label: el.last_name + ' ' + el.first_name + ' ' + el.middle_name,
          });
          tmpRoles.push({
            id: el.uuid_role,
            label: el.role_name,
          });
        });
        setSpeakers(tmpSpeakers);
        setSpeakersRoles(tmpRoles);
      }
    }
    // console.log(blogItem?.ts_to);
  }, [blogItem]);

  const setSpeakersRolesByKey = useCallback(
    (value: UsersType, key: number) => {
      if (speakersRoles && value) {
        const tmp = [...speakersRoles];
        tmp[key] = value;
        setSpeakersRoles(tmp);
      }
    },
    [speakersRoles]
  );
  const setSpeakersByKey = useCallback(
    (value: UsersType, key: number) => {
      if (speakers && value) {
        const tmp = [...speakers];
        tmp[key] = value;
        console.log(tmp);
        setSpeakers(tmp);
      }
    },
    [speakers]
  );

  const addSpeaker = useCallback(() => {
    if (speakers) {
      const tmp = [...speakers];
      tmp.push({ label: '', id: '' });
      setSpeakers(tmp);
    }
  }, [speakers]);

  const deleteSpeaker = useCallback(
    (ind: number) => {
      if (speakers) {
        const tmp = [...speakers].filter((el, key) => key !== ind);
        setSpeakers(tmp);
      }
    },
    [speakers]
  );

  const saveNews = useCallback(() => {
    const tmp: any = {
      name: name,
      description: textValue,
      type: typeValue?.slug,
      picture: cover || blogItem?.cover?.uuid,
    };
    if (checked && publishDateValue) {
      tmp.publication_date = publishDateValue;
    }
    if (tagsValue?.length) {
      tmp['tags[]'] = tagsValue.map((el) => el.uuid || el.name);
    }
    if (typeValue.slug === 'announcement') {
      tmp.ts_from = eventDateFrom;
      tmp.ts_to = eventDateTo;
      tmp.place_name = place || '';
      tmp.link = link || '';
      if (speakers) {
        let i = 0;
        speakers.map((person, idx) => {
          tmp[`persons[${i}][uuid_person]`] = person.id;
          console.log('before update ', speakersRoles);
          tmp[`persons[${i}][uuid_role]`] = speakersRoles ? speakersRoles[idx].id : '';
          i++;
        });
      }
    }
    // console.log(tmp);
    if (!uuid) {
      dispatch(postBlogNewsThunk(tmp)).then((data) => {
        setUuid(data.payload.data._uuid);
        dispatch(
          pushSnackBarItemReports({
            key: Math.random(),
            message: `Материал Создан!`,
            status: 'success',
            autoClose: 5,
          })
        );
        navigate(`/admin/blog/news/${data.payload.data._uuid}`);
      });
    } else {
      tmp._method = 'PUT';
      const data = { data: tmp, uuid: uuid };
      dispatch(updateBlogNewsThunk(data)).then((data: any) => {
        setUuid(data.payload.data._uuid);
        dispatch(
          pushSnackBarItemReports({
            key: Math.random(),
            message: `Материал обновлен!`,
            status: 'success',
            autoClose: 5,
          })
        );
        navigate(`/admin/blog/news/${data.payload.data._uuid}`);
      });
    }
  }, [
    tagsValue,
    typeValue,
    cover,
    textValue,
    name,
    dispatch,
    uuid,
    eventDateFrom,
    publishDateValue,
    eventDateTo,
    place,
    link,
    speakers,
    speakersRoles,
  ]);

  const publishNews = useCallback(() => {
    dispatch(publishBlogNewsThunk(uuid)).then((data: any) => {
      navigate(`/admin/blog`);
    });
  }, [uuid, dispatch]);
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.breadcrumbs}>
          <div className={classes.breadcrumbsLink} onClick={() => navigate('/admin/blog')}>
            Администрирование блога
          </div>
          <IconArrowRight size="s" view="ghost" className={classes.breadcrumbsArrow} />
          <div className={classes.breadcrumbsCurrent}>{uuid ? 'Измнение' : 'Добавление'} материала</div>
        </div>
        <div className={classes.title}>{uuid ? 'Измeнение' : 'Добавление'} материала</div>
        {!uuid && (
          <div className={classes.formItem}>
            <div className={classes.formItemTitle}>Тип материала</div>
            <ChoiceGroup
              size={'s'}
              value={typeValue}
              onChange={(value) => setTypeValue(value.value)}
              items={types}
              getItemLabel={(item: ViewSelectorItemType) => item.name}
              multiple={false}
              name={'adminBlogPageViewSelector'}
            />
          </div>
        )}
        <div className={classes.formItem}>
          <div className={classes.formItemTitle}>О материале</div>
          <TextField
            onChange={(value) => setName(value.value)}
            label="Название"
            value={name}
            type="text"
            placeholder="Введите название"
            className={classes.fullWidth}
          />
        </div>
        <div className={classNames([classes.newsAction, classes.fullWidth])}>
          <Combobox
            style={{ zIndex: 1 }}
            label="Тэги"
            items={tagList}
            value={tagsValue}
            onChange={(value) => setTagsValue(value)}
            getItemLabel={(el) => el.name}
            getItemKey={(el) => el.name}
            multiple
            aria-placeholder="Выберите тэги"
            className={classes.fullWidth}
            onCreate={(label) => {
              setTagList([{ name: label, uuid: '' }, ...tagList]);
              setTagsValue([{ name: label, uuid: '' }, ...(tagsValue as BlogItemTagType[])]);
            }}
          />
        </div>
        {typeValue?.slug === 'announcement' && (
          <div className={classes.formItem}>
            <div className={classes.formItemTitle}>Время и место проведения</div>
            <div className={classes.formItem}>
              <DatePicker
                label={'Дата и время начала'}
                type="date-time"
                value={eventDateFrom}
                onChange={(value: any) => setEventDateFrom(value.value)}
                style={{ zIndex: 1 }}
              />
            </div>
            <div className={classes.formItem}>
              <DatePicker
                label={'Дата и время окончания'}
                type="date-time"
                value={eventDateTo}
                onChange={(value: any) => setEventDateTo(value.value)}
                style={{ zIndex: 1 }}
              />
            </div>
            <div className={classes.formItem}>
              <TextField
                onChange={(value) => setPlace(value.value)}
                label="Место проведения"
                value={place}
                type="text"
                placeholder="Введите название места"
                className={classes.fullWidth}
              />
            </div>
            <div className={classes.formItem}>
              <TextField
                onChange={(value) => setLink(value.value)}
                label="Ссылка (при наличии)"
                value={link}
                type="text"
                placeholder="Введите ссылку"
                className={classes.fullWidth}
              />
            </div>
          </div>
        )}
        <div className={classes.formItem}>
          <div className={classes.formItemTitle}>Обложка</div>
          <DragNDropField onDropFiles={(files) => setCoverValue(files[0])} className={classes.fullWidth}>
            {({ openFileDialog }) => (
              <>
                <IconPhoto size={'l'} view={'ghost'} className={classes.photo} />
                <Text>
                  Перетащите изображение или{' '}
                  <a className={classes.link} onClick={openFileDialog}>
                    загрузите
                  </a>
                </Text>
                <Text view="ghost" font="mono">
                  Поддерживаемые форматы: PNG, TIFF, JPG
                </Text>
              </>
            )}
          </DragNDropField>
          {cover && (
            <div className={classNames([classes.attachment, classes.fullWidth])}>
              <Attachment
                key={cover.name}
                fileName={cover.name}
                fileExtension={cover.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                fileDescription={cover.type}
              />
              <IconTrash view={'ghost'} onClick={() => setCoverValue(null)} />
            </div>
          )}
          {!cover && blogItem?.cover?.path && (
            <div className={classNames([classes.attachment, classes.fullWidth])}>
              <img
                src={blogItem?.cover?.path}
                alt={blogItem?.name}
                onClick={() => window.open(blogItem?.cover?.path)}
              />
            </div>
          )}
        </div>
        <div className={classNames([classes.formItem, classes.fullWidth])}>
          <div className={classes.formItemTitle}>Текст</div>
          <CustomEditor
            placeholder={'Введите описание материала'}
            value={textValue}
            onChange={(...event: any[]) => setTextValue(event[0])}
          />
        </div>
        {typeValue?.slug === 'announcement' && (
          <div className={classes.formItem}>
            <div className={classes.formItemTitle}>Спикеры</div>
            {speakers?.map((el: UsersType, key) => (
              <div>
                <div className={classes.speakerTitle}>
                  Спикер №{key + 1}{' '}
                  {key !== 0 && (
                    <IconTrash
                      view={'ghost'}
                      size={'s'}
                      onClick={() => deleteSpeaker(key)}
                      className={classes.pointer}
                    />
                  )}
                </div>
                <div className={classes.fullWidth}>
                  <UserSelect
                    initValue={el.id ? el : undefined}
                    onChangeInit={(value) => setSpeakersByKey(value, key)}
                    label={'Сотрудник'}
                  />
                </div>

                <Combobox
                  style={{ zIndex: 999 }}
                  label="Роль"
                  key={key}
                  items={roles}
                  value={speakersRoles ? speakersRoles[key] : null}
                  onChange={(value: any) => {
                    setSpeakersRolesByKey(value, key);
                  }}
                  getItemLabel={(el: UsersType) => el.label}
                  getItemKey={(el: UsersType) => el.id}
                  onSearchValueChange={setSearch}
                  searchValue={searchValue}
                  className={classes.fullWidth}
                />
              </div>
            ))}
            <Button size={'s'} view={'ghost'} label={'Добавить еще'} iconLeft={IconAdd} onClick={addSpeaker} />
          </div>
        )}
        <div className={classes.formItem}>
          <div className={classes.formItemTitle}>Настройки публикации</div>
          <Switch
            view="primary"
            checked={checked}
            label={'Опубликовать позже'}
            onChange={({ checked }) => setIsChecked(checked)}
          />
          <div className={classes.formItem}>
            <DatePicker
              style={{ zIndex: 999 }}
              type="date-time"
              value={publishDateValue}
              onChange={(value: any) => setPublishDaeValue(value.value)}
              disabled={!checked}
            />
          </div>
        </div>
        <div className={classNames([classes.newsAction, classes.fullWidth])}>
          <Button view={'ghost'} label={'Отмена'} size={'m'} onClick={() => navigate('/admin/blog')} />
          <div>
            <Button
              view={'ghost'}
              label={'Предпросмотр'}
              size={'m'}
              disabled={!uuid}
              onClick={() => window.open(`/blog/${typeValue?.slug}/${uuid}`)}
            />
            <Button
              view={'ghost'}
              label={'Сохранить'}
              size={'m'}
              onClick={saveNews}
              disabled={!((cover || blogItem?.cover?.path) && name && textValue)}
            />
            <Button
              view={'primary'}
              label={'Опубликовать'}
              size={'m'}
              onClick={publishNews}
              disabled={
                !((cover || blogItem?.cover?.path) && name && textValue && uuid && blogItem?.status?.slug != 'done')
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBlogNewsForm;
