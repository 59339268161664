import instanceAxios from './instanceAxios';

export const teamApi = {
  async getTeamTeam() {
    const response = await instanceAxios.get('/team/teams');
    return response.data;
  },
  async getTeamDomains() {
    const response = await instanceAxios.get('/team/domains');
    return response.data;
  },
  async getTeamUnits() {
    const response = await instanceAxios.get('/team/units');
    return response.data;
  },
  async getTeamPrograms() {
    const response = await instanceAxios.get('/team/programs');
    return response.data;
  },
  async getGroupsByDomain() {
    const response = await instanceAxios.get('/team/domains/groups-by-domain');
    return response.data.data;
  },
  async getTeamUsers(search?: string) {
    const response = await instanceAxios.get(`/team/persons?filter[search]=${search}`);
    return response.data.map((el: any) => {
      return {
        label: el.last_name + ' ' + el.first_name + ' ' + (el.middle_name || ''),
        id: el._uuid,
        icon: el.icon,
      };
    });
  },
  async getTeamBlocks() {
    const response = await instanceAxios.get('/dictionaries/blocks');
    return response.data.data.map((el: any) => {
      return {
        label: el.name,
        id: el._uuid,
      };
    });
  },
  async getTeamDivisions() {
    const response = await instanceAxios.get('/team/team/team-divisions');
    return response.data.map((el: any) => {
      return {
        label: el.name,
        id: el._uuid,
        ord: el.ord,
      };
    });
  },
  async postTeamDivisions(divisions: any[]) {
    const response = await instanceAxios.post('/team/team/team-divisions', {
      team_divisions: divisions,
    });
    return response.data;
  },
  async postCommandDomainGroups(groups: any[], uuid_block: string) {
    const response = await instanceAxios.post('/team/domains/groups', {
      team_groups: groups,
      uuid_block: uuid_block,
    });
    return response.data;
  },
  async postLeader(uuid_block: string, uuid_person: string, position: string) {
    const response = await instanceAxios.post('/team/team/leader', {
      uuid_block: uuid_block,
      uuid_person: uuid_person,
      position: position,
    });
    return response.data;
  },
  async postEmployee(uuid_block: string, uuid_person: string, position: string, uuid_team_division: string) {
    const response = await instanceAxios.post('/team/team/employee', {
      uuid_block: uuid_block,
      uuid_person: uuid_person,
      position: position,
      uuid_team_division: uuid_team_division,
    });
    return response.data;
  },
  async deleteEmployee(uuid: string) {
    const response = await instanceAxios.delete('/team/team/employee', { data: { _uuid: uuid } });
    return response.data;
  },
  async postConfluence(uuid_block: string, uuid_team_division: string, name: string, link: string) {
    const response = await instanceAxios.post('/team/team/confluence', {
      uuid_block: uuid_block,
      uuid_team_division: uuid_team_division,
      name: name,
      link: link,
    });
    return response.data;
  },
  async deleteConfluence(uuid: string) {
    const response = await instanceAxios.delete('/team/team/confluence', { data: { _uuid: uuid } });
    return response.data;
  },
  async deleteDomain(uuid: string) {
    const response = await instanceAxios.delete(`/team/domains/${uuid}`, {});
    return response.data;
  },
  async deleteUnit(uuid: string) {
    const response = await instanceAxios.delete(`/team/units/${uuid}`, {});
    return response.data;
  },
  async deleteProgram(uuid: string) {
    const response = await instanceAxios.delete(`/team/programs/${uuid}`, {});
    return response.data;
  },
  async postUnit(unitData: any) {
    const response = await instanceAxios.post('/team/units/units', unitData);
    return response.data;
  },
  async postProgram(unitData: any) {
    const response = await instanceAxios.post('/team/programs/programs', unitData);
    return response.data;
  },
  async postTeamDomain(domainData: any) {
    const response = await instanceAxios.post('/team/domains/domains', domainData);
    return response.data;
  },
};
