import React, { useState } from 'react';

import { BlogAnnouncementType } from '../../api/blogServerResponse';
import { getDateTimeForBlogEvent } from '../../utils/blogUtils/getDateTimeForBlogEvent';
import { getDayOfWeekForDate } from '../../utils/blogUtils/getDayOfWeekForDate';
import classes from './BlogSubscribeBlock.module.css';
import ModalSubscribe from './ModalSubscribe/ModalSubscribe';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

interface ISubscribeBlockProps {
  announce: BlogAnnouncementType | undefined;
}

const BlogSubscribeBlock: React.FC<ISubscribeBlockProps> = ({ announce }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onClickSubscribe = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={classes.container}>
      <Text className={classes.dateTime}>{getDateTimeForBlogEvent(announce?.start_date)}</Text>
      <Text className={classes.day}>{getDayOfWeekForDate(announce?.start_date)}</Text>
      <Text className={classes.description}>{`${announce?.format || ''} «${announce?.name}»`}</Text>
      <Button label={`Записаться`} onClick={onClickSubscribe} disabled />
      <Button
        label={`Стать спикером`}
        view={'secondary'}
        onClick={onClickSubscribe}
        style={{ marginTop: '16px' }}
        disabled
      />
      <ModalSubscribe isOpen={isModalOpen} closeModal={closeModal} format={announce?.format} title={announce?.name} />
    </div>
  );
};

export default BlogSubscribeBlock;
